var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"window"},[_c('header',{staticClass:"header"},[_vm._m(0),_c('a',{staticClass:"header__support",attrs:{"href":_vm.$i18n.t('nav.notion_link'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$i18n.t("nav.support")))]),_c('change-locale',{staticClass:"header__locale"}),_c('custom-btn',{staticClass:"header__logout button button_theme_white",on:{"click":_vm.logout}},[_c('span',{staticClass:"logout_text_midl"},[_vm._v(_vm._s(_vm.$i18n.t("nav.logout")))]),_c('div',{staticClass:"logout__img"})])],1),_c('div',[(_vm.$router.currentRoute.name === 'status')?_c('nav',{staticClass:"layout__nav_status"},[_c('router-link',{staticClass:"layout__link layout__link_status",attrs:{"to":"/monitoring"}},[_vm._v(_vm._s(_vm.$t("nav.monitoring")))]),_c('div',{staticClass:"layout__status-arrow"}),_c('div',{staticClass:"layout__status-text"},[_vm._v(" "+_vm._s(_vm.$t("monitoring_owner.headers.status"))+" "+_vm._s(_vm.$router.currentRoute.params.id)+" ")])],1):_c('nav',{staticClass:"layout__nav"},[(
          _vm.$auth.check({ name: 'manager' }) ||
          _vm.$auth.check({ name: 'admin' }) ||
          _vm.$auth.check({ name: 'superadmin' })
        )?_c('router-link',{staticClass:"layout__link",attrs:{"active-class":"layout__link_visit","to":"/monitoring"}},[_vm._v(_vm._s(_vm.$t("nav.monitoring")))]):_vm._e(),(
          _vm.$auth.check({ permissions: 'add_hubs' }) ||
          _vm.$auth.check({ permissions: 'hub_settings' }) ||
          _vm.$auth.check({ name: 'admin' })
        )?_c('router-link',{staticClass:"layout__link",attrs:{"active-class":"layout__link_visit","to":"/hubs"}},[_vm._v(_vm._s(_vm.$t("nav.hubs")))]):_vm._e(),(
          _vm.$auth.check({ permissions: 'application_permission' }) ||
          _vm.$auth.check({ name: 'admin' })
        )?_c('router-link',{staticClass:"layout__link",attrs:{"active-class":"layout__link_visit","to":"/applications"}},[_vm._v(_vm._s(_vm.$t("nav.applications")))]):_vm._e(),(
          _vm.$auth.check({ permissions: 'add_users' }) ||
          _vm.$auth.check({ name: 'admin' })
        )?_c('router-link',{staticClass:"layout__link",attrs:{"active-class":"layout__link_visit","to":"/team"}},[_vm._v(_vm._s(_vm.$t("nav.team")))]):_vm._e(),(_vm.$auth.check({ name: 'admin' }))?_c('router-link',{staticClass:"layout__link",attrs:{"active-class":"layout__link_visit","to":"/accounts"}},[_vm._v(_vm._s(_vm.$t("nav.accounts")))]):_vm._e(),(
          _vm.$auth.check({ name: 'manager' }) || _vm.$auth.check({ name: 'admin' })
        )?_c('router-link',{staticClass:"layout__link",attrs:{"active-class":"layout__link_visit","to":"/settings"}},[_vm._v(_vm._s(_vm.$t("nav.settings")))]):_vm._e(),(_vm.$auth.check({ name: 'superadmin' }))?_c('router-link',{staticClass:"layout__link",attrs:{"active-class":"layout__link_visit","to":"/customers"}},[_vm._v(_vm._s(_vm.$t("nav.customers")))]):_vm._e()],1),_c('router-view')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header__logo"},[_c('img',{attrs:{"width":"160","height":"19","src":require("@/assets/logo.svg"),"alt":"logo"}})])}]

export { render, staticRenderFns }