<template>
  <div class="window">
    <header class="header">
      <div class="header__logo">
        <img width="160" height="19" src="@/assets/logo.svg" alt="logo" />
      </div>
      <a
        :href="$i18n.t('nav.notion_link')"
        target="_blank"
        class="header__support"
        >{{ $i18n.t("nav.support") }}</a
      >
      <change-locale class="header__locale"></change-locale>
      <custom-btn
        class="header__logout button button_theme_white"
        @click="logout"
      >
        <span class="logout_text_midl">{{ $i18n.t("nav.logout") }}</span>
        <div class="logout__img" />
      </custom-btn>
    </header>
    <div>
      <nav
        class="layout__nav_status"
        v-if="$router.currentRoute.name === 'status'"
      >
        <router-link
          class="layout__link layout__link_status"
          to="/monitoring"
          >{{ $t("nav.monitoring") }}</router-link
        >
        <div class="layout__status-arrow"></div>
        <div class="layout__status-text">
          {{ $t("monitoring_owner.headers.status") }}
          {{ $router.currentRoute.params.id }}
        </div>
      </nav>

      <nav v-else class="layout__nav">
        <router-link
          v-if="
            $auth.check({ name: 'manager' }) ||
            $auth.check({ name: 'admin' }) ||
            $auth.check({ name: 'superadmin' })
          "
          class="layout__link"
          active-class="layout__link_visit"
          to="/monitoring"
          >{{ $t("nav.monitoring") }}</router-link
        >
        <router-link
          v-if="
            $auth.check({ permissions: 'add_hubs' }) ||
            $auth.check({ permissions: 'hub_settings' }) ||
            $auth.check({ name: 'admin' })
          "
          class="layout__link"
          active-class="layout__link_visit"
          to="/hubs"
          >{{ $t("nav.hubs") }}</router-link
        >
        <router-link
          v-if="
            $auth.check({ permissions: 'application_permission' }) ||
            $auth.check({ name: 'admin' })
          "
          class="layout__link"
          active-class="layout__link_visit"
          to="/applications"
          >{{ $t("nav.applications") }}</router-link
        >
        <router-link
          v-if="
            $auth.check({ permissions: 'add_users' }) ||
            $auth.check({ name: 'admin' })
          "
          class="layout__link"
          active-class="layout__link_visit"
          to="/team"
          >{{ $t("nav.team") }}</router-link
        >
        <router-link
          v-if="$auth.check({ name: 'admin' })"
          class="layout__link"
          active-class="layout__link_visit"
          to="/accounts"
          >{{ $t("nav.accounts") }}</router-link
        >
        <router-link
          v-if="
            $auth.check({ name: 'manager' }) || $auth.check({ name: 'admin' })
          "
          class="layout__link"
          active-class="layout__link_visit"
          to="/settings"
          >{{ $t("nav.settings") }}</router-link
        >

        <router-link
          v-if="$auth.check({ name: 'superadmin' })"
          class="layout__link"
          active-class="layout__link_visit"
          to="/customers"
          >{{ $t("nav.customers") }}</router-link
        >
        <!--        -->
      </nav>
      <router-view />
    </div>
  </div>
</template>

<script>
import changeLocale from "@/components/components/changeLocale";
import customBtn from "@/components/recurringComponents/customBtn";
import { baseUrl } from "@/plugins/http";

export default {
  name: "AppLayoutAdmin",
  components: {
    changeLocale,
    customBtn,
  },
  data: function () {
    return {
      user: this.$auth.user(),
    };
  },
  created() {
    if (this.$socket === undefined) {
      let wsurl = new URL(baseUrl).host;
      this.$connect(
        `//${wsurl}/feed?token=${localStorage.getItem("access_token")}`
      );
    }
  },
  computed: {},
  methods: {
    logout() {
      localStorage.removeItem("refresh_token");
      this.$store.dispatch("clear_all_filters");
      this.$disconnect();
      this.$auth.logout();
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
