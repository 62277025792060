<template>
  <div>
    <span
      class="change-local__item"
      :class="{ 'change-local_strong': !is_en_locale }"
      @click="set_locale('ru')"
      >Ру</span
    >
    <span class="change-local__separator">|</span>
    <span
      class="change-local__item"
      :class="{ 'change-local_strong': is_en_locale }"
      @click="set_locale('en')"
      >En</span
    >
  </div>
</template>

<script>
export default {
  name: "changeLocale",
  computed: {
    is_en_locale() {
      return this.$i18n.locale === "en";
    },
  },
  created() {
    if (!localStorage.getItem("locale")) {
      this.set_locale("ru");
    }
  },
  methods: {
    set_locale(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped></style>
